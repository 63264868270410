import server from "@/utils";
import cookie from 'js-cookie'

export const setcsrf = () => {
    return server({
        url:'/api/setcsrf',
        method:"get"
    })
}

export const SelectData = (data:any) => {
    return server({
        url:"/api/SelectData",
        method:'post',
        data
    })
}

export const getAllAction = () => {
    return server({
        url:'/api/action/getallaction',
        method:'post',
        headers:{
            'x-csrf-token':cookie.get('csrfToken')
        }
    },

)}

export const getActionDeatil = (data:any) => {
    return server({
        url:'/api/action/getactiondeatil',
        method:'post',
        data
    })
}

export const AddClient = (data:any) => {
    return server({
        url:"/api/client/clientadd",
        method:'post',
        data
    })
}

export const getSwiper = (data:any) => {
    return server({
        url:'/api/home/getswiper',
        method:"post",
        data,
    })
}

export const getHomedeatil = (data:any) => {
    return server({
        url:'/api/home/homedeatil',
        method:'post',
        data
    })
}

export const getAllNews = (data:any) => {
    return server({
        url:'/api/news/getnews',
        method:'post',
        data
    })
}

export const Selectnews = (data:any) =>{
    return server({
        url:"/api/news/select",
        method:'post',
        data
    })
}

export const getupnews = (data:any) => {
    return server({
        url:'/api/news/upnews',
        method:'post',
        data
    })
}

export const getnewsimage = (data:any) => {
    return server({
        url:'/api/selectnewsimage',
        method:"post",
        data
    })
}

export const getvisits = () => {
    return server({
        url:"/api/home/visits",
        method:"post"
    })
}

export const SendCode = (data:any) => {
    return server({
        url:'/api/sendsms',
        method:'post',
        data
    })
}

export const SendEmail = (data:any) => {
    return server({
        url:'/api/sendemail',
        method:'post',
        data
    })
}


export const HaveClient = (data:any) => {
    return  server({
        url:'/api/client/haveclient',
        method:"post",
        data
    })
}

export const SubData = (data:any) => {
    return server({
        url:"/api/sub/subdata",
        method:'post',
        data
    })
}

export const selectClient = (data:any) => {
    return server({
        url:"/api/client/selectclient",
        method:'post',
        data
    })
}